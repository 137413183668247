import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { Web3ReactProvider } from '@web3-react/core'
import { QueryClientProvider, QueryClient } from "react-query"
import { ToastContainer } from 'react-toastify';

import App from "./App";
import Dashboard from "./pages/Dashboard";
import Nodes from "./pages/Nodes";
import HowToMint from "./pages/HowToMint";
import NotFound from "./pages/NotFound";

import { connectors } from "./config"
import { ModalProvider } from "./module/modal"
import { WalletModal } from "./components/WalletModal"
import { SecureModal } from "./components/SecureModal"
import { AttackModal } from "./components/AttackModal"

const queryClient = new QueryClient();

function Routes() {
    return (
        <BrowserRouter>
            <Route render={(props)=>(
                <Web3ReactProvider connectors={connectors}>
                    <QueryClientProvider client={queryClient}>
                        <ModalProvider>
                            <App {...props}>
                                <Switch>
                                    <Route path="/" exact component={Dashboard}/>
                                    <Route path="/dashboard" exact component={Dashboard}/>
                                    <Route path="/igloos" component={Nodes}/>
                                    <Route path="/how-to-mint" component={HowToMint}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            </App>
                            <WalletModal />
                            <SecureModal />
                            <AttackModal />
                            <ToastContainer theme="dark" />
                        </ModalProvider>
                    </QueryClientProvider>
                </Web3ReactProvider>
            )}/>
        </BrowserRouter>
    )
}

export default Routes;

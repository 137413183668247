import React from "react"
import { useWeb3React } from "@web3-react/core"
import { Modal, FormControl } from "react-bootstrap"
import { getNetwork, metamask } from "../config";
import { useModal, MODAL_TYPES } from "../module/modal"

import mm from '../assets/images/mm.png';
import twt from '../assets/images/twt.png';

const { REACT_APP_CHAIN_ID } = process.env;
const network = getNetwork(REACT_APP_CHAIN_ID);

export const WalletModal = () => {
    const { isActive } = useWeb3React();
    const { isOpen, store, close } = useModal();
    const { modalType }                = store || {};

    const title = !isActive
        ? 'Please connect your wallet'
        : `Connected to ${network.chainName}`;

    const body = !isActive
        ? <InactiveWalletModalBody />
        : <ActiveWalletModalBody />;

    const displayModal = isOpen && (modalType === MODAL_TYPES.WALLET_MODAL);

    return (
        <Modal className="sp-modal" centered show={displayModal} onHide={close}>
            <Modal.Header>
                <Modal.Title className="text-sp">{title}</Modal.Title>
                <button onClick={close} type="button" className="close"><span aria-hidden="true">×</span><span
                    className="sr-only">Close</span></button>
            </Modal.Header>
            {body}
        </Modal>
    )
};

const InactiveWalletModalBody = () => {
    const { isActive } = useWeb3React();
    /** Metamask is activating is buggy */
    if (isActive) return null;

    return (
        <Modal.Body>
            <div className="col-12">
                <div className="row">
                    <div className="col-xl-6 col-sm-6 col-12 mb-2 mb-xl-0">
                        <button className="sp-connector btn waves-effect" onClick={async () => { await metamask.activate(network) }}>
                            <div className="metamask-btn d-inline-flex">
                                <img className="mr-1" alt="Metamask Logo" src={ mm } height="25px" />
                                <p className="text-sp m-0">Metamask</p>
                            </div>
                        </button>
                    </div>
                    <div className="col-xl-6 col-sm-6 col-12 mb-2 mb-xl-0">
                        <button className="sp-connector btn" onClick={async () => { await metamask.activate(network) }}>
                            <div className="twt-btn d-inline-flex">
                                <img  className="mr-1" alt="Trustwallet Logo" src={ twt }  height="25px" />
                                <p className="text-sp m-0">Trustwallet</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Modal.Body >
    )
};

const ActiveWalletModalBody = () => {
    const { connector, isActive, account } = useWeb3React();
    const { close } = useModal();

    if (!isActive) return null;

    return (
        <Modal.Body className="font-weight-bolder text-center">
            <FormControl value={account} style={{ textAlign: 'center' }} readOnly />
            <button className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp mt-2"
                    onClick={async () => await connector.deactivate()}>
                Disconnect
            </button>
            <button className="btn btn-gradient-secondary waves-effect waves-float waves-light text-sp ml-1 mt-2" onClick={close}>
                OK
            </button>
        </Modal.Body>
    )
};

import { useWeb3React } from "@web3-react/core"
import { BigNumber } from "ethers"
import { useQueryClient, useMutation } from "react-query"
import { toast } from 'react-toastify';
import { getMulticallProvider, getTokenContract, getLiqTokenContract } from "../contracts"
import { useQueryWithDefault, getNodesAddress } from "./utils"
import { constants } from "../config/constants";

const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
const defaultTokenInfo  = {
    symbol: '',
    decimals: 0,
    balance: BigNumber.from(0),
    isApproved: false,
};

const keys = (key) => {
    return {
        all: (account) => [key, account ?? '', 'all'],
        symbol: () => [key, 'symbol'],
        decimals: () => [key, 'decimals'],
        balance: (account) => [key, account ?? '', 'balance'],
        isApproved: (account) => [key, account ?? '', 'isApproved'],
    }
};

const tokenContract = getTokenContract();
const liqContract = getLiqTokenContract();
const nodesContractAddress = getNodesAddress();


const getTokenSymbol = async (isToken = true) => {
    const contract  = isToken ? tokenContract.read() : liqContract.read();
    const symbol    = await contract.symbol();
    return symbol;
};

const getTokenDecimals = async (isToken = true) => {
    const contract = isToken ? tokenContract.read() : liqContract.read();
    const decimals = await contract.decimals();

    return decimals;
};

const getTokenBalance = async (account, isToken = true) => {
    const contract  = isToken ? tokenContract.read() : liqContract.read();
    const balance   = await contract.balanceOf(account);

    return balance;
};

const getIsTokenApproved = async (account, isToken = true)  => {
    const contract  = isToken ? tokenContract.read() : liqContract.read();
    const allowance = await contract.allowance(account, nodesContractAddress);

    return allowance.gt(0);
};

const getTokenInfo = async (account, isToken = true) => {
    const contract  = isToken ? tokenContract.multi() : liqContract.multi();
    const provider  = getMulticallProvider();

    const [symbol, decimals, balance, allowance] = await provider.all([
        contract.symbol(),
        contract.decimals(),
        contract.balanceOf(account),
        contract.allowance(account, nodesContractAddress),
    ]);

    return { symbol, decimals, balance, isApproved: allowance.gt(0) };
};

const approveToken = async (signer, isToken = true) => {
    const contract  = isToken ? tokenContract.write(signer) : liqContract.write(signer);
    const tx        = await contract.approve(nodesContractAddress, MAX_UINT256);

    await tx.wait();
};

const useTknBalance = (init = BigNumber.from(0), isToken = true) => {
    const { account }   = useWeb3React();
    const key           = isToken ? 'token' : 'liq';

    return useQueryWithDefault(init, keys(key).balance(account), async () => {
        if (!account) return null;

        return getTokenBalance(account, isToken);
    })
};

const useIsTokenApproved = (init = false, isToken = true) => {
    const { account } = useWeb3React();
    const key         = isToken ? 'token' : 'liq';

    return useQueryWithDefault(init, keys(key).isApproved(account), async () => {
        if (!account) return null;

        return getIsTokenApproved(account, isToken);
    })
};

const useTknInfo = (init = defaultTokenInfo, isToken = true) => {
    const { account }   = useWeb3React();
    const key           = isToken ? 'token' : 'liq';

    return useQueryWithDefault(init, keys(key).all(account), async () => {
        if (!account) return null;

        return getTokenInfo(account, isToken);
    });
};

const useApproveTkn = (isToken = true) => {
    const client                = useQueryClient();
    const { provider, account } = useWeb3React();
    const key                   = isToken ? 'token' : 'liq';
    const symbolMsg             = isToken ? constants.BASE_TOKEN_SYMBOL : constants.LIQ_TOKEN_SYMBOL;

    return useMutation(async () => {
        if (!provider) return;
        if (!account) return;

        await toast.promise(
            approveToken(provider.getSigner(), isToken),
            {
                pending: 'Approving ' + symbolMsg + ' Token ...',
                success: symbolMsg + ' successfully approved',
                error: symbolMsg + ' Approve failed'
            }
        );
    }, {
        onSuccess: () => {
            client.invalidateQueries(keys(key).all(account));
            client.invalidateQueries(keys(key).isApproved(account));
        }
    })
};

/***************************************************************************
 ******************************** BASE TOKEN ********************************
 ***************************************************************************/

export const useTokenDecimals = (init = 0) => {
    return useQueryWithDefault(init, keys('token').decimals(), () => getTokenDecimals())
};

export const useTokenSymbol = (init = 0) => {
    return useQueryWithDefault(init, keys('token').symbol(), () => getTokenSymbol())
};

export const useTokenBalance = (init = BigNumber.from(0)) => {
    return useTknBalance(init);
};

export const useTokenInfo = (init = defaultTokenInfo) => {
    return useTknInfo(init);
};

export const useApproveToken = () => {
    return useApproveTkn();
};

/***************************************************************************
 ******************************** LIQ TOKEN ********************************
 ***************************************************************************/

export const useLiqDecimals = (init = 0) => {
    return useQueryWithDefault(init, keys('liq').decimals(), () => getTokenDecimals(false))
};

export const useLiqSymbol = (init = 0) => {
    return useQueryWithDefault(init, keys('liq').symbol(), () => getTokenSymbol(false))
};

export const useLiqBalance = (init = BigNumber.from(0)) => {
    return useTknBalance(init, false);
};

export const useIsLiqApproved = (init = false) => {
    return useIsTokenApproved(init, false);
};

export const useLiqInfo = (init = defaultTokenInfo) => {
    return useTknInfo(init, false);
};

export const useApproveLiq = () => {
    return useApproveTkn(false);
};

import React, {useCallback, useState} from "react"
import {InputGroup, Modal} from "react-bootstrap"
import { useModal, MODAL_TYPES } from "../module/modal"
import { useWeb3React } from "@web3-react/core";
import { nodesList } from "../config";
import { useSecure } from "../hooks/nodes";
import {useTokenInfo} from "../hooks/token";

const useToken = () => {
    const tokenInfo = useTokenInfo();

    return {
        isLoading: tokenInfo.isLoading,
        data: { ...tokenInfo.data }
    }
};

export const SecureModal = () => {
    const { isActive }  = useWeb3React();
    const secure        = useSecure();

    const { isOpen, store, close }  = useModal();
    const { modalType, modalProps } = store || {};
    const { node, nodeInfo, onSuccess }        = modalProps || {};

    const [quantity, setQuantity] = useState(1);
    const increment = useCallback(() => setQuantity(q => q + 1), [])
    const decrement = useCallback(() => setQuantity(q => q === 1 ? 1 : q - 1), [])

    const token = useToken();

    if (!isActive || !node) return null;

    const handleClose = () => {
        setQuantity(1);
        close();
    };

    const displayModal = isOpen && modalType === MODAL_TYPES.SECURE_MODAL;
    const title = nodesList[node.id] !== undefined ? 'Secure your "' + nodesList[node.id].name + '"' : 'Secure Your Nodes';
    return (
        <Modal size="lg" className="sp-modal" centered show={ displayModal } onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="text-sp">Antartica Protection</Modal.Title>
                <button onClick={handleClose} type="button" className="close"><span aria-hidden="true">×</span><span
                    className="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body className="font-weight-bolder text-center">
                <h3 className="text-sp">Secure you Igloos against attacks</h3>

                {(nodesList[node.id].image !== undefined) &&
                    <div className="row">
                        <div className="col-12">
                            <img style={{maxHeight: '155px'}} className="img-fluid" src={nodesList[node.id].image} alt={ title } />
                        </div>
                    </div>
                }
                <div className="row mt-1">
                    <div className="mx-auto col-6">
                        <h3 className="text-sp mb-2">{ nodesList[node.id].name }</h3>
                        <h5 className="text-sp">Price  : {quantity * nodeInfo.securityTax} {token.data.symbol}</h5>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="mx-auto col-6">
                        <InputGroup>
                            <button className="btn btn-gradient-primary text-sp mr-50" onClick={ decrement } disabled={quantity === 1}>-</button>
                            <input readOnly className="text-sp form-control" value={quantity} style={{ textAlign: 'center', borderRadius: '0.358rem' }} />
                            <button className="btn btn-gradient-primary text-sp ml-50" onClick={increment} disabled={quantity >= (node.count - node.securedCount)}>+</button>
                        </InputGroup>
                        <small className="form-text text-muted mb-1">You can secure up to :  {node.count - node.securedCount} Igloos</small>
                    </div>
                </div>

                <button className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp mt-2"
                        onClick={() => secure.mutate({ nodeId : node.id, quantity : quantity, fct : handleClose }, { onSuccess: () => { if (onSuccess !== undefined) {onSuccess()} }})}>
                    Purchase Protection
                </button>
            </Modal.Body>
        </Modal>
    )
};

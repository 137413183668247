import React from 'react';

const NotFound = () => (
    <div>
        <h2>404</h2>
        404
    </div>
);

export default NotFound;

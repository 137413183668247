import React, {useEffect, useState} from "react";
import { X } from 'react-feather';
import { SiTwitter, SiTelegram } from 'react-icons/si'
import { menu } from "../config";

import logo from "../assets/images/logo-menu.png";

function Sidebar(props, {defaultActive,}) {
    const location = props.history.location;
    const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
    const lastActiveIndex = Number(lastActiveIndexString);
    const [activeIndex, setActiveIndex] = useState(lastActiveIndex || defaultActive);

    function changeActiveIndex(newIndex) {
        localStorage.setItem("lastActiveIndex", newIndex);
        setActiveIndex(newIndex)
    }

    function getPath(path) {
        if (path.charAt(0) !== "/") {
            return  "/" + path;
        }
        return path;
    }

    useEffect(()=> {
        const activeItem = menu.findIndex(item=> getPath(item.route) === getPath(location.pathname))
        changeActiveIndex(activeItem);
    }, [location]);

    return (
        <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
            <div className="navbar-header">
                <ul className="nav navbar-nav flex-row">
                    <li className="nav-item mx-auto">
                        <a className="navbar-brand" href="/">
                            <span className="brand-logo">
                                <img src={logo} alt="platform-logo" />
                            </span>
                        </a>
                    </li>
                    <li className="nav-item nav-toggle">
                        <a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse" href={() => false}>
                            <i className="d-block d-xl-none text-white toggle-icon font-medium-4"><X /></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="shadow-bottom"></div>

            <div className="main-menu-content">
                <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                    <li className=" navigation-header">
                        <span className="text-sp text-secondary" data-i18n="Apps &amp; Pages">Pages</span>
                    </li>
                    {
                        menu.map((item, index)=> {
                            return (
                                <li key={index} className={(index === activeIndex) ? "nav-item active" : "nav-item"}>
                                    <a className="d-flex align-items-center" href={item.route}>
                                        {(index === activeIndex) ?
                                            <img className="mr-1" src={item.icon_dark} alt="Section icon" width="20" height="20"/> :
                                            <img className="mr-1" src={item.icon_light} alt="Section icon" width="20" height="20"/>
                                        }
                                        <span className="menu-title text-sp text-truncate">{ item.name }</span>
                                    </a>
                                </li>
                            );
                        })
                    }

                    <hr />
                    <li className=" navigation-header">
                        <span className="text-sp text-secondary" data-i18n="Apps &amp; Pages">Socials</span>
                        <i data-feather="more-horizontal"></i>
                    </li>
                    <li className="nav-item">
                        <a className="d-flex align-items-center" href="https://t.me/eskimo_finance" target="_blank" rel="noopener noreferrer">
                            <SiTelegram />
                            <span className="menu-title text-sp text-truncate" data-i18n="Telegram">Telegram</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="d-flex align-items-center" href="https://twitter.com/EskimoFi_Base" target="_blank" rel="noopener noreferrer">
                            <SiTwitter />
                            <span className="menu-title text-sp text-truncate" data-i18n="Discord">Twitter</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;


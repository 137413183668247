import {useQuery} from "react-query";
import addresses from '../contracts/addresses';
import {ethers} from "ethers";

export const useQueryWithDefault = (init, key, f) => {
    const query = useQuery(key, f);

    return { ...query, data: query.data || init };
};

export const getAddress = (address) => {
    const mainNetChainId    = 42161;
    const chainId           = process.env.REACT_APP_CHAIN_ID;
    return address[chainId] ? address[chainId] : address[mainNetChainId];
};

export const getNodesAddress = () => {
    return getAddress(addresses.nodes).toLowerCase();
};

export const getTokenAddress = () => {
    return getAddress(addresses.token).toLowerCase();
};

export const getLiqTokenAddress = () => {
    return getAddress(addresses.liq).toLowerCase();
};

export const getVaultAddress = () => {
    return getAddress(addresses.vault).toLowerCase();
};

export const getMulticallAddress = () => {
    return getAddress(addresses.multicall).toLowerCase();
};

export const getSwapUrl = () => {
    return `https://app.uniswap.org/swap?inputCurrency=${getAddress(addresses.liq)}&outputCurrency=${getAddress(addresses.token)}&chain=${process.env.REACT_APP_CHAIN_NAME}`;
}

export const decodeTxEvent = (receipt, abi, eventName) => {
    let iface = new ethers.utils.Interface(abi);
    const result =  receipt.logs.map(log => {
        try {
            return iface.decodeEventLog(eventName, log.data, log.topics)
        } catch (e) {
            console.log('Error while decoding Event');
            console.error(e);
            return [];
        }
    });

    return result.filter(x => x !== undefined);
};

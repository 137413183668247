import React, { createContext, useContext, useState } from "react"

export const MODAL_TYPES = {
    WALLET_MODAL:"WALLET_MODAL",
    SECURE_MODAL:"SECURE_MODAL",
    ATTACK_MODAL: "ATTACK_MODAL",
    DETAILS_MODAL: "DETAILS_MODAL"
};

const initalState = {
    isOpen: false,
    open: () => {},
    close: () => {},
    store: {},
};

export const ModalContext = createContext(initalState);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [store, setStore] = useState();

    const open = (modalType, modalProps = {}) => {
        setStore({
            ...store,
            modalType,
            modalProps,
        });
        setIsOpen(true);
    };

    const close = () => {
        setStore({
            ...store,
            modalType: null,
            modalProps: {},
        });
        setIsOpen(false);
    };

    const value = { isOpen, store, open, close };

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    )
};

import React from 'react'
import { ethers } from "ethers"
import makeBlockie from 'ethereum-blockies-base64';
import { Menu, Home, FileText, DollarSign, Activity } from 'react-feather';
import { Spinner } from "react-bootstrap";

import { useWeb3React } from "@web3-react/core";
import { useModal, MODAL_TYPES } from "../module/modal"
import {useTokenBalance, useTokenSymbol} from "../hooks/token";
import {getSwapUrl} from "../hooks/utils";
import {useTokenPrice} from "../hooks/vault";

function Header() {
    const { isActive, account } = useWeb3React();
    const balance= useTokenBalance();
    const symbol= useTokenSymbol();
    const tokenPrice = useTokenPrice();

    const { open } = useModal();
    const OpenWalletModal = () => {
        open(MODAL_TYPES.WALLET_MODAL);
    };

    return (
        <span>
            <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">
                <div className="navbar-container d-flex content menu-header-bg">
                    <div className="bookmark-wrapper d-flex align-items-center">
                        <ul className="nav navbar-nav d-xl-none">
                            <li className="nav-item">
                                <a className="nav-link menu-toggle" href={() => false}>
                                    <Menu />
                                </a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav bookmark-icons">
                            <li className="nav-item d-none d-lg-block">
                                {!tokenPrice.isLoading &&
                                    <><Activity size={15} /><span className="ml-75 mr-2 d-none d-md-inline-block text-sp mt-75">1 ESK = ${tokenPrice.data}</span></>
                                }

                            </li>
                            <li className="nav-item d-none d-lg-block">
                               <div className="media">
                                   { !symbol.isLoading &&
                                   <a className="btn btn-gradient-secondary round waves-effect" href={getSwapUrl()} target="_blank" rel="noopener noreferrer">
                                       <DollarSign size={15} /><span className="text-sp text-white ml-75">Buy {symbol.data}</span>
                                   </a>
                                   }
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ul className="nav navbar-nav align-items-center ml-auto">
                        <li className="nav-item">
                            <a href="https://eskimo.finance" target="_blank" rel="noopener noreferrer" className="nav-link">
                                <span className="btn btn-gradient-secondary waves-effect waves-float waves-light">
                                    <Home size={15} /><span className="ml-75 d-none d-md-inline-block text-sp">Home Website</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://eskimo.gitbook.io/eskimo-finance" target="_blank" rel="noopener noreferrer" className="nav-link">
                                <span className="btn btn-gradient-secondary waves-effect waves-float waves-light">
                                    <FileText size={15} /><span className="ml-75 d-none d-md-inline-block text-sp">Docs</span>
                                </span>
                            </a>
                        </li>
                        {!isActive ? (
                            <li className="nav-item"><button className="btn btn-gradient-secondary waves-effect waves-float waves-light text-sp ml-75" onClick={ OpenWalletModal }>Connect</button></li>
                        ) : (
                            <li className="nav-item dropdown dropdown-user ">
                                <a className="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user"
                                   href={() => false}  aria-haspopup="true"
                                   aria-expanded="false">
                                    <div onClick={OpenWalletModal} className="user-nav d-sm-flex d-none">
                                    <span id="wallet" className=" user-name font-weight-bolder sm-txt">
                                        { account.slice(0, 6) + '...' + account.slice(-4) }
                                    </span>
                                        <span className="user-status">
                                            { balance.isLoading && symbol.isLoading
                                                ? <div className="text-center">
                                                    <Spinner animation="grow" />
                                                  </div>
                                                : parseFloat(ethers.utils.formatUnits(balance.data.toString())).toFixed(2) + ' ' + symbol.data
                                            }
                                        </span>
                                    </div>
                                    { account ? (
                                        <span onClick={OpenWalletModal} className="avatar">
                                        <img className="round" src={ makeBlockie(account) } alt="avatar" height="40" width="40" />
                                    </span>
                                    ) : (
                                        <div className="spinner-grow text-primary ml-1" height="40" width="40" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) }
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </span>
    )
}

export default Header

import React from 'react';

import { getNetwork } from "../config";
import { ethers } from 'ethers';
import {useWeb3React} from "@web3-react/core";
import ConnectButton from "../components/ConnectButton";
import { CheckSquare } from "react-feather";
import {useApproveToken, useApproveLiq, useTokenInfo, useLiqInfo} from "../hooks/token";
import {Spinner} from "react-bootstrap";
import {getSwapUrl} from "../hooks/utils";

const useTokens = () => {
    const tokenInfo = useTokenInfo();
    const liqInfo = useLiqInfo();

    return {
        isLoading: tokenInfo.isLoading || liqInfo.isLoading,
        token: { ...tokenInfo.data },
        liq: { ...liqInfo.data },
    }
};

const HowToMint = () => {
    const { REACT_APP_CHAIN_ID } = process.env;
    const { chainId, isActive }  = useWeb3React();
    const network                = getNetwork(REACT_APP_CHAIN_ID);

    const tokens                = useTokens();

    const approveToken            = useApproveToken();
    const approveLiq            = useApproveLiq();

    const changeWalletNetwork = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: ethers.utils.hexlify(REACT_APP_CHAIN_ID) }],
                });
                window.location.reload();

            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            getNetwork(REACT_APP_CHAIN_ID),
                        ],
                    });
                    window.location.reload();
                }
            }
        }
    };

    return (
        <div className="mx-2" id="how-to-buy">
            <h1 className="text-sp text-primary mb-3">How to buy an Igloo ?</h1>

            <div className="row">
                <div className="mx-auto col-xl-10 col-md-12 col-12">
                    <div className="card card-faq">
                        <div className="card-header mb-75">
                            <h2 className="mb-1 text-white text-sp">
                                <i className="font-large-1" data-feather="info"></i> How to mint ?
                            </h2>
                            <small className="text-white">Step by step tutorial</small>
                        </div>
                        <div className="card-body statistics-body">
                            <div className="collapse-margin collapse-icon mt-2" id="faq-payment-qna">

                                { /******* 1. Setup Configured Network *******/ }
                                <div className="card">
                                    <div className="card-header" id="paymentOne" data-toggle="collapse" role="button"
                                         data-target="#faq-payment-one" aria-expanded="false" aria-controls="faq-payment-one">
                                        <span className="lead card-title text-sp">1. Setup "{ network.chainName }" Network</span>
                                    </div>

                                    <div id="faq-payment-one" className="collapse" aria-labelledby="paymentOne"
                                         data-parent="#faq-payment-qna">
                                        <div className="card-body text-white">
                                            { !isActive
                                                ? <div className="mx-auto text-center mb-1"> <ConnectButton btnLabel="Please connect"/> </div>
                                                : (chainId.toString() === REACT_APP_CHAIN_ID.toString())
                                                    ? <div className="text-center text-sp">
                                                        <p><span className="text-secondary"><CheckSquare className="mr-1" />{ network.chainName } </span> Detected !</p>
                                                        <p>You are good to go</p>
                                                    </div>
                                                    : <div className="text-center mb-1">
                                                        <button className="btn btn-gradient-primary text-sp" onClick={ changeWalletNetwork }>Change Network</button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/******* 2. Buy NATIVE & LIQUIDITY TOKENS *******/}
                                <div className="card">
                                    <div className="card-header" id="paymentTwo" data-toggle="collapse" role="button"
                                         data-target="#faq-payment-two" aria-expanded="false" aria-controls="faq-payment-two">
                                        <span className="lead card-title text-sp">2. Buy { network.nativeCurrency.symbol } & { tokens.liq.symbol }</span>
                                    </div>
                                    <div id="faq-payment-two" className="collapse" aria-labelledby="paymentTwo"
                                         data-parent="#faq-payment-qna">
                                        <div className="card-body text-white">
                                            <p>Buy some <strong><span className="text-secondary">{ network.nativeCurrency.symbol }</span></strong> and
                                                <strong><span className="text-secondary"> { tokens.liq.symbol }</span></strong>,
                                                enough for your Igloo choice, from a central exchange like Binance, Kucoin or Coinbase or any decentralized exchange
                                            </p>
                                            <p>You will need these two tokens to mint an Igloo</p>
                                        </div>
                                    </div>
                                </div>

                                {/******* 3. Swap NATIVE For TOKEN *******/}
                                <div className="card">
                                    <div className="card-header" id="paymentThree" data-toggle="collapse" role="button"
                                         data-target="#faq-payment-three" aria-expanded="false" aria-controls="faq-payment-three">
                                        <span className="lead card-title text-sp">3. Swap { network.nativeCurrency.symbol } for { tokens.token.symbol }</span>
                                    </div>
                                    <div id="faq-payment-three" className="collapse" aria-labelledby="paymentThree"
                                         data-parent="#faq-payment-qna">
                                        <div className="card-body text-white">
                                            <p>Go to <a href={ getSwapUrl() } target="_blank" rel="noreferrer">
                                                <strong><span className="text-secondary">Uniswap </span></strong>
                                            </a>
                                                and swap your <strong><span className="text-secondary">{ network.nativeCurrency.symbol } </span></strong>
                                                for the equivalent <strong><span className="text-secondary">{ tokens.token.symbol } </span></strong>
                                                needed for your node choice
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/******* 4. Approve TOKEN & LIQUIDITY TOKENS *******/}
                                <div className="card">
                                    <div className="card-header" id="paymentFour" data-toggle="collapse" role="button"
                                         data-target="#faq-payment-four" aria-expanded="false" aria-controls="faq-payment-four">
                                        <span className="lead card-title text-sp">4. Approve { tokens.token.symbol } and { tokens.liq.symbol } Tokens</span>
                                    </div>
                                    <div id="faq-payment-four" className="collapse" aria-labelledby="paymentFour"
                                         data-parent="#faq-payment-qna">
                                        <div className="card-body text-white">
                                            <div className="row justify-content-center mb-1">
                                                <div className="col-12 col-md-4">
                                                    {!tokens.token.isApproved
                                                        ? <button className="btn btn-gradient-primary text-sp w-100" onClick={() => approveToken.mutate()} disabled={ approveToken.isLoading }>
                                                            Approve { tokens.token.symbol } {approveToken.isLoading && <Spinner as="span" animation="border" size="sm" />}
                                                        </button>
                                                        : <button className="btn btn-gradient-primary text-sp mb-1 w-100" disabled={ true }>{ tokens.token.symbol } already approved</button>
                                                    }
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    {!tokens.liq.isApproved
                                                        ? <button className="btn btn-gradient-primary text-sp w-100" onClick={() => approveLiq.mutate()} disabled={ approveLiq.isLoading }>
                                                            Approve { tokens.liq.symbol } {approveLiq.isLoading && <Spinner as="span" animation="border" size="sm" />}
                                                        </button>
                                                        : <button className="btn btn-gradient-primary text-sp mb-1 w-100" disabled={ true }>{ tokens.liq.symbol } already approved</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/******* 5. Mint your nodes *******/}
                                <div className="card mb-1">
                                    <div className="card-header" id="roundOver" data-toggle="collapse" role="button"
                                         data-target="#faq-round-over" aria-expanded="false" aria-controls="faq-round-over">
                                        <span className="lead card-title text-sp">5. Mint your Igloo</span>
                                    </div>
                                    <div id="faq-round-over" className="collapse" aria-labelledby="roundOver"
                                         data-parent="#faq-payment-qna">
                                        <div className="card-body text-white">
                                            <p>Go to <a href="/igloos"><span className="text-sp text-secondary">My IGLOOS</span></a> and mint the type of Igloo and quantity of your choice</p>
                                            <p>Now relax and enjoy your passive income from your Igloo(s) or even better, tweet about us using the button below and stand a chance to win one free Igloo.</p>
                                            <div className="text-center mb-1">
                                                <a className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp" target="_blank" rel="noreferrer"
                                                   href="https://twitter.com/intent/tweet?text=Check%20out%20%40EskimoFi_Base%2C%20the%20first%20decentralized%20liquidity%20owning%20yield%20protocol%20on%20base%20chain.%0AUp%20to%201%25%20ROI%20daily%0A%0A%23ESK%20%23base%20%23Igloo%20%23BasePrinter%20%23printETH%20%23ETHprinter%20%23ETH%20%23EskimoFinance%20%23Giveaway%20%23Giveaways"
                                                >
                                                    Tweet about us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToMint;

import {useWeb3React} from "@web3-react/core"
import {ethers} from "ethers"
import {useMutation} from "react-query"
import {toast} from 'react-toastify';
import {getMulticallProvider, getNodesContract, getTokenContract} from "../contracts"
import {decodeTxEvent, getNodesAddress, useQueryWithDefault} from "./utils"
import {nodesAttackMessages, nodesList} from "../config";

const nodesInstance = getNodesContract();
const tokenInstance   = getTokenContract();

const getNodesAnalytics = async (account) => {
    let userTotalNodes  = 0;
    let userDailyRewards  = 0;

    const userNodes     = await getUserNodes(account);
    const nodeList      = await getNodeList(account);

    for (const node of userNodes) {
        userTotalNodes += node.count;
        userDailyRewards += node.count * nodeList[node.id].multiplier
    }

    const provider = getMulticallProvider();
    const nodesContract = nodesInstance.multi();
    const tokenContract = tokenInstance.multi();

    const [pendingRewardsWei, tokenInNodesWei, totalNodesWei] = await provider.all([
        nodesContract.calculateAllInterests(account),
        tokenContract.balanceOf(getNodesAddress()),
        nodesContract.totalNodes()
    ]);

    const pendingRewards    = parseFloat(ethers.utils.formatUnits(pendingRewardsWei));
    const tokenInNodes        = parseFloat(ethers.utils.formatUnits(tokenInNodesWei));
    const totalNodes        = totalNodesWei.toNumber();

    return { userTotalNodes, pendingRewards , tokenInNodes, totalNodes, userDailyRewards };
};

const getNumberOfAvailableNodes = async () => {
    const contract = nodesInstance.read();
    const numberOfAvailableNodes = await contract.totalNodeTypes();
    return parseInt(numberOfAvailableNodes.toString());
};

const getUserHistorySize = async (account) => {
    const contract = nodesInstance.read();
    const count = await contract.getUserHistorySize(account);

    return parseInt(count.toString());
};

const getUserHistory = async (account) => {
    const count = await getUserHistorySize(account);
    if (count <= 0) return count;

    const provider  = getMulticallProvider();
    const contract  = nodesInstance.multi();
    const calls     = [];

    for (let i = 0; i < count; i++) {
        calls.push(contract.attackEvents(account, i));
    }

    const history = await provider.all(calls);
    return history.map(([eventDate, id, eventType, won, amount], index) => ({
        id: parseInt(id.toString()),
        eventDate: parseInt(eventDate.toString()),
        eventType: eventType,
        won: won,
        amount: parseInt(amount.toString()),
    }));
};

const getUserNodes = async (account) => {
    const availableNodes = await getNumberOfAvailableNodes();

    const provider = getMulticallProvider();
    const contract = nodesInstance.multi();

    const calls             = [];
    const canAttackWithNode = [];

    for (let i = 0; i < availableNodes; i++) {
        calls.push(contract.userNodes(account, i));
        canAttackWithNode.push(contract.canAttack(account, i));
    }

    const nodes = await provider.all(calls);
    const attackResults = await provider.all(canAttackWithNode);

    return nodes.filter(n => n.exists).map(([exists, id , count, lastClaim, securedCount, lastAttack, debt], index) => ({
        exists,
        id: parseInt(id.toString()),
        count: parseInt(count.toString()),
        lastClaim: parseInt(lastClaim.toString()),
        securedCount: parseInt(securedCount.toString()),
        lastAttack: parseInt(lastAttack.toString()),
        debt: parseInt(debt.toString()),
        canAttack: attackResults[id] !== undefined ? attackResults[id] : false
    }));
};

const getNodeList = async () => {
    const max = await getNumberOfAvailableNodes();

    const provider = getMulticallProvider();
    const contract = nodesInstance.multi();

    const calls = [];

    for (let i = 0; i < max; i++) {
        calls.push(contract.nodeInfo(i));
    }

    const nodeList = await provider.all(calls);

    return nodeList.map(([exists, token, liq, mul, securityTax], nodeId) => ({
        nodeId,
        exists,
        tokenValue: parseInt(token.toString()),
        liqValue: parseInt(liq.toString()),
        multiplier: parseInt(mul.toString()),
        securityTax: parseInt(securityTax.toString())
    }));
};

const getUserPendingRewardsInfo = async (account) => {
    const provider      = getMulticallProvider();
    const contract      = nodesInstance.multi();

    const calls         = [];
    const userNodes     = await getUserNodes(account);
    let userTotalNodes  = 0;

    for (const node of userNodes) {
        if (nodesList[node.id] !== undefined) {
            userTotalNodes += node.count;
            calls.push(contract.calculateNodeInterests(account, node.id));
        }
    }

    let rewards = await provider.all(calls);
    rewards = rewards.map((nodeReward, index) => {
        return parseFloat(ethers.utils.formatUnits(nodeReward.toString()));
    });

    const userTotalPendingRewards   = await nodesInstance.read().calculateAllInterests(account);
    return {
        rewards,
        userTotalNodes,
        userTotalPendingRewards : parseFloat(ethers.utils.formatUnits(userTotalPendingRewards.toString()))
    };
};

const canNodeAttack = async (account, nodeId) => {
    const contract = nodesInstance.read();

    return await contract.canAttack(account, nodeId);
};

const mintNodes = async (signer, nodeId, quantity) => {
    const contract  = nodesInstance.write(signer);
    const tx        = await contract.mint(nodeId, quantity);
    return await tx.wait();
};

const secure = async (signer, nodeId, quantity) => {
    const contract  = nodesInstance.write(signer);
    const tx        = await contract.secureNodes(nodeId, quantity);
    return await tx.wait();
};

const attack = async (signer, account, nid) => {
    const contract  = nodesInstance.write(signer);
    const tx        = await contract.attack(nid);
    const receipt   = await tx.wait();

    const decodeAttack  = decodeTxEvent(receipt, ["event AttackResult(address indexed attacker, uint256 nodeId, uint8 code)"], 'AttackResult');
    const decodeSuccess = decodeTxEvent(receipt, ["event SuccessfulAttack(address indexed attacker, address indexed victim, uint256 amountEarned)"], 'SuccessfulAttack');
    const decodeFailure = decodeTxEvent(receipt, ["event FailedAttack(address indexed attacker, address indexed victim, uint256 amountEarned)"], 'FailedAttack');

    return {
        attack: decodeAttack.length > 0 ? decodeAttack : false,
        success: decodeSuccess.length > 0 ? decodeSuccess : false,
        failure: decodeFailure.length > 0 ? decodeFailure : false,
    }
};

const withdraw = async (signer, nodeId) => {
    const contract  = nodesInstance.write(signer);
    const tx        = await contract.withdraw(nodeId);
    return await tx.wait();
};

const withdrawAll = async (signer) => {
    const contract  = nodesInstance.write(signer);
    const tx        = await contract.withdrawAll();
    return await tx.wait();
};

/** query hooks */
export const useNodeList = () => {
    return useQueryWithDefault([], 'nodes', () => getNodeList())
};

export const useUserNodes = () => {
    const { account } = useWeb3React();
    return useQueryWithDefault([], ['userNodes', account], async () => {
        if (!account) return null;

        return getUserNodes(account);
    });
};

export const useUserHistory = () => {
    const { account } = useWeb3React();
    return useQueryWithDefault([], ['userHistory', account], async () => {
        if (!account) return null;

        return getUserHistory(account);
    });
};

export const useNodesAnalytics = () => {
    const { account } = useWeb3React();
    return useQueryWithDefault([], ['analytics', account], async () => {
        if (!account) return null;

        return getNodesAnalytics(account)
    });
};

export const useMintNodes = () => {
    const { provider } = useWeb3React();

    return useMutation(async ({ nodeId, quantity }) => {
        if (!provider) return;

        await toast.promise(
            mintNodes(provider.getSigner(), nodeId, quantity),
            {
                pending: 'Minting Igloos ...',
                success: 'Igloos successfully minted',
                error: 'Failed to mint Igloos'
            }
        );
    })
};

export const useWithdraw = () => {
    const { provider } = useWeb3React();

    return useMutation(async ({ nodeId }) => {
        if (!provider) return;

        await toast.promise(
            withdraw(provider.getSigner(), nodeId),
            {
                pending: 'Collecting rewards ...',
                success: 'Rewards successfully collected',
                error: 'Collecting rewards failed'
            }
        );
    })
};

export const useWithdrawAll = () => {
    const { provider } = useWeb3React();

    return useMutation(async () => {
        if (!provider) return;

        await toast.promise(
            withdrawAll(provider.getSigner()),
            {
                pending: 'Collecting all rewards ...',
                success: 'Rewards successfully collected',
                error: 'Collecting rewards failed'
            }
        );
    })
};

export const useSecure = () => {
    const { provider } = useWeb3React();

    return useMutation(async ({ nodeId, quantity, fct }) => {
        if (!provider) return;

        await toast.promise(
            secure(provider.getSigner(), nodeId, quantity),
            {
                pending: 'Requesting Antarctica Protection...',
                success: 'Accepted, your Igloos are now protected',
                error: 'Error, Failed to reach Antarctica'
            }
        );
        fct();
    })
};

export const useAttack = () => {
    const { account, provider } = useWeb3React();

    return useMutation(async ({ nodeId, fct }) => {
        if (!provider || !account) return;

        const id = toast.loading('Searching random Igloo to attack ...');
        const results = await attack(provider.getSigner(), account, nodeId);

        if (results.attack !== false) { // Draw for a reason
            for (const result of results.attack) {
                toast.update(id, {
                    render: nodesAttackMessages[result.code],
                    type: "info",
                    isLoading: false,
                    autoClose: 7000
                });
            }
        } else {
            toast.dismiss(id);
        }


        if (results.success !== false) { // Won
            console.log('result success', results.success);
            toast.update(id, {
                render: "Congrats !! You attacked and won the battle",
                type: "success",
                isLoading: false,
                autoClose: 7000
            });
        } else if (results.failure !== false) { // Lost
            console.log('result failure', results.failure);
            toast.update(id, {
                render: "Oups !! You lost while attacking the Igloo",
                type: "error",
                isLoading: false,
                autoClose: 7000
            });
        }

        console.log('result of attack', results);

        fct();
    });
};

export const useCanNodeAttack = (nid) => {
    const { account } = useWeb3React();
    return useQueryWithDefault([], ['nodeCanAttack', account, nid], async () => {
        if (!account) return null;

        return canNodeAttack(account, nid);
    });
};

export const useUserPendingRewardsInfo = () => {
    const { account } = useWeb3React();

    return useQueryWithDefault([], ['rewardsInfo', account], async () => {
        if (!account) return null;

        return getUserPendingRewardsInfo(account);
    })
};

import {useWeb3React} from "@web3-react/core";
import {getTokenAddress, useQueryWithDefault} from "./utils";
import {getUniswapPairContract, getVaultContract} from "../contracts";

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
const vaultInstance = getVaultContract();

const getTokenPrice = async (account) => {
    const contract = vaultInstance.read();
    const primaryPair = await contract.getPrimaryPair();

    if (primaryPair && (primaryPair !== ZERO_ADDRESS)) {
        const pairContract = getUniswapPairContract(primaryPair).read();
        const token0 = await pairContract.token0();

        const reserves = await pairContract.getReserves();
        let tokenReserves, liqReserves;
        if (token0.toLowerCase() === getTokenAddress()) {
            tokenReserves = reserves.reserve0;
            liqReserves = reserves.reserve1;
        } else {
            tokenReserves = reserves.reserve1;
            liqReserves = reserves.reserve0;
        }

        const price = liqReserves / tokenReserves;
        return Math.round(price * 100) / 100;
    }

    return 0;
};

export const useTokenPrice = () => {
    const { account } = useWeb3React();
    return useQueryWithDefault([], ['tokenPrice', account], async () => {
        if (!account) return null;

        return getTokenPrice(account)
    });
};
import { initializeConnector } from "@web3-react/core"
import { MetaMask } from "@web3-react/metamask"
import { networkParams } from "./networks";

/** chainIds list */
const chainIds = Object.keys(networkParams).map((chainId) => parseInt(chainId));

/** initialize connectors */
const [metamask, metamaskHooks] = initializeConnector(actions => {
    return new MetaMask(actions, true)
}, chainIds);

/** export individual connectors */
export { metamask }

/** export individual hooks */
export { metamaskHooks }

/** export an array of connectors and their hooks */
export const connectors  = [
    [metamask, metamaskHooks]
]

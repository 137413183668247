import { factory } from './factory';
import {getTokenAddress, getLiqTokenAddress, getNodesAddress, getVaultAddress} from "../hooks/utils";

import NodesAbiJson from './abi/Nodes.json';
import TokenAbiJson from './abi/Token.json';
import LiqTokenAbiJson from './abi/LiqToken.json';

export const getNodesContract = () => {
    return factory(NodesAbiJson.abi)(getNodesAddress())
};

export const getTokenContract = () => {
    return factory(TokenAbiJson.abi)(getTokenAddress())
};

export const getLiqTokenContract = () => {
    return factory(LiqTokenAbiJson.abi)(getLiqTokenAddress())
};

export const getVaultContract = () => {
    return factory(['function getPrimaryPair() external view returns (address)'])(getVaultAddress())
};

export const getUniswapPairContract = (pairAddress) => {
    return factory([
        'function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
        'function token0() external view returns (address)',
        'function token1() external view returns (address)'
    ])(pairAddress)
}

export * from "./factory"


import React from 'react';

import { useNodesAnalytics, useWithdrawAll } from '../hooks/nodes';
import {useWeb3React} from "@web3-react/core";
import { useModal, MODAL_TYPES } from "../module/modal";
import { Spinner } from "react-bootstrap";

import tokenLogo from '../assets/images/token.png';
import mm from '../assets/images/mm.png';
import {getSwapUrl, getTokenAddress} from "../hooks/utils";
import { useTokenInfo } from "../hooks/token";
import {useTokenPrice} from "../hooks/vault";
import {constants} from "../config/constants";

const ConnectButton = () => {
    const { open } = useModal();
    const OpenWalletModal       = () => {
        open(MODAL_TYPES.WALLET_MODAL);
    };
    return (
        <button className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp mt-2" onClick={ OpenWalletModal }>Connect</button>
    );
};

const useToken = () => {
    const tokenInfo = useTokenInfo();

    return {
        isLoading: tokenInfo.isLoading,
        data: { ...tokenInfo.data }
    }
};

const addTokenToWallet = async () => {
    await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
                address: getTokenAddress(), // The address that the token is at.
                symbol: 'ESK', // A ticker symbol or shorthand, up to 5 chars.
                decimals: 18, // The number of decimals in the token
                image: 'https://bafkreia53ydlufw36yueyfdhjyqk4lbwlnxsrski27xqqvzhadcnttmyiy.ipfs.nftstorage.link' // A string url of the token logo
            }
        }
    })
}

const Dashboard = () => {

    const { isActive }  = useWeb3React();
    const analytics     = useNodesAnalytics();
    const withdrawAll   = useWithdrawAll();
    const token = useToken();
    const tokenPrice = useTokenPrice();

    return (
        <div className="mx-2" id="dashboard">
            <h1 className="text-primary text-sp my-3">Dashboard</h1>
            <div className="row mx-lg-5 h-100">
                <div className="col-md-4 col-sm-12 mb-1 pl-lg-3">
                    <div className="card text-center card-nodes-dashboard rounded h-100">
                        <div className="card-body dashboard-stats">
                            <img src={tokenLogo} alt="Token logo" height="137px"/>
                            <p className="dashboard-stats-title text-primary mt-2">Current Price</p>
                            { tokenPrice.isLoading
                                ? <div className="text-center text-primary mb-2"><Spinner animation="grow" /></div>
                                : <p className="dashboard-stats-value my-2">
                                    { tokenPrice.data }<span className="dashboard-stats-title text-primary"> { constants.LIQ_TOKEN_SYMBOL}</span>
                                </p>
                            }

                            <p><a href={getSwapUrl()} className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp ">Buy {token.data.symbol}</a></p>
                            <button className="btn metamask-add-btn waves-effect mt-1" onClick={ addTokenToWallet }>
                                <div className="d-inline-flex">
                                    <img className="mr-1" alt="Metamask Logo" src={ mm } height="25px" />
                                    <p className="text-sp m-0">+ Add to Metamask</p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-md-8 col-sm-12 my-auto">
                    <div className="row ">
                        <div className="col-md-6 col-sm-12 mb-1">
                            <div className="card text-center card-nodes-dashboard rounded h-100">
                                <div className="card-body dashboard-stats">
                                    <h5 className="dashboard-stats-title text-primary mt-2">My pending rewards</h5>
                                    { isActive
                                        ? <>
                                            { analytics.isLoading
                                                ? <div className="text-center text-primary mt-2"><Spinner animation="grow" /></div>
                                                : <div className="dashboard-stats-value my-1">
                                                    {analytics.data.pendingRewards} <span className="dashboard-stats-title text-primary"> { token.data.symbol }</span>
                                                </div>

                                            }
                                            { !analytics.isLoading && (analytics.data.pendingRewards > 0) &&
                                                <button  onClick={ () => withdrawAll.mutate() }
                                                         className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp">Collect All</button>
                                            }
                                        </>
                                        : <ConnectButton />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-1 pr-lg-3">
                            <div className="card text-center card-nodes-dashboard rounded h-100">
                                <div className="card-body dashboard-stats">
                                    <h5 className="dashboard-stats-title text-primary mt-2">YOUR DAILY REWARDS</h5>
                                    <p className="dashboard-stats-value my-2">
                                        {analytics.data.userDailyRewards} <span className="dashboard-stats-title text-primary">{token.data.symbol}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-6 col-sm-12 mb-1">
                            <div className="card text-center card-nodes-dashboard rounded h-100">
                                <div className="card-body dashboard-stats">
                                    <h5 className="dashboard-stats-title text-primary mt-2">Total Number of Igloos</h5>
                                    { isActive
                                        ? <div className="dashboard-stats-value my-1">{ analytics.isLoading
                                            ? <div className="text-center">
                                                <Spinner animation="grow" />
                                            </div>
                                            : analytics.data.totalNodes
                                        }</div>
                                        : <ConnectButton />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 mb-1 pr-lg-3">
                            <div className="card text-center card-nodes-dashboard rounded h-100">
                                <div className="card-body dashboard-stats">
                                    <h5 className="dashboard-stats-title text-primary mt-2">{token.data.symbol} IN REWARDS POOL</h5>
                                    { isActive
                                        ? <>
                                            { analytics.isLoading
                                                ? <div className="text-center text-primary mt-2"><Spinner animation="grow" /></div>
                                                : <div className="dashboard-stats-value my-1">
                                                    {analytics.data.tokenInNodes.toFixed(2)} <span className="dashboard-stats-title text-primary">{token.data.symbol}</span>
                                                </div>
                                        }</>
                                        : <ConnectButton />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row row-flex mx-lg-5">
                <div className="col-md-4 col-sm-12 mb-1 pl-lg-3">
                    <div className="card text-center card-nodes-dashboard rounded h-100">
                        <div className="card-body dashboard-stats">
                            <p className="dashboard-stats-title text-primary mt-2">Number of my Igloos</p>
                            { isActive
                                ? <>
                                    <div className="dashboard-stats-value my-1">{ analytics.isLoading
                                        ?  <div className="text-center">
                                               <Spinner animation="grow" />
                                           </div>
                                        : analytics.data.userTotalNodes
                                    }</div>
                                    <a href="/igloos" className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp">View my Igloos</a>
                                  </>
                                : <ConnectButton />
                            }
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 mb-1">
                    <div className="card text-center card-nodes-dashboard rounded h-100">
                        <div className="card-body dashboard-stats">
                            <h5 className="dashboard-stats-title text-primary mt-2">Win a free Igloo !</h5>
                            <p className="card-text text-primary text-bold">Tweet about us and try to win a free Igloo !</p>
                            <a className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp" target="_blank" rel="noreferrer"
                               href="https://twitter.com/intent/tweet?text=Check%20out%20%40EskimoFi_Base%2C%20the%20first%20decentralized%20liquidity%20owning%20yield%20protocol%20on%20base%20chain.%0AUp%20to%201%25%20ROI%20daily%0A%0A%23ESK%20%23base%20%23Igloo%20%23BasePrinter%20%23printETH%20%23ETHprinter%20%23ETH%20%23EskimoFinance%20%23Giveaway%20%23Giveaways"
                            >
                                Tweet about US</a>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12 mb-1 pr-lg-3">
                    <div className="card text-center card-nodes-dashboard rounded h-100">
                        <div className="card-body dashboard-stats">
                            <h5 className="dashboard-stats-title text-primary mt-2">Documentation</h5>
                            <p className="card-text text-primary text-bold">Read about Eskimo Finance</p>
                            <a href="https://eskimo.gitbook.io/eskimo-finance" className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp">Read the docs</a>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
};

export default Dashboard;

import React, { useState, useCallback } from 'react';
import { ethers } from "ethers"
import { useWeb3React } from "@web3-react/core";
import { Spinner, InputGroup } from "react-bootstrap";
import { nodesList } from "../../config";
import { useMintNodes } from "../../hooks/nodes";
import { useApproveToken, useApproveLiq } from "../../hooks/token";
import ConnectButton from "../../components/ConnectButton";

const MintButton = ({ node, quantity, tokens, reloadIt }) => {
    const approveToken = useApproveToken();
    const approveLiq = useApproveLiq();
    const mint = useMintNodes();

    // In case token info is still loading
    if (tokens.isLoading) return (
        <button className="btn btn-gradient-primary">
            <Spinner as="span" animation="grow" />
        </button>
    );

    // in case one or both tokens are not approved
    if ((!tokens.token.isApproved && (node.tokenValue > 0)) || (!tokens.liq.isApproved && (node.liqValue > 0))) return (
        <>
            {!tokens.token.isApproved && (node.tokenValue > 0) && (
                <button className="btn btn-gradient-primary text-sp mb-1 w-100" onClick={() => approveToken.mutate()} disabled={ approveToken.isLoading }>
                    Approve { tokens.token.symbol } {approveToken.isLoading && <Spinner as="span" animation="border" size="sm" />}
                </button>
            )}

            {!tokens.liq.isApproved && (node.liqValue > 0) && (
                <button className="btn btn-gradient-primary text-sp w-100" onClick={() => approveLiq.mutate()} disabled={ approveLiq.isLoading }>
                    Approve { tokens.liq.symbol } {approveLiq.isLoading && <Spinner as="span" animation="border" size="sm" />}
                </button>
            )}
        </>
    );

    // in case user does not have enough balance for the requested igloo quantity
    const tokenTotal = ethers.utils.parseUnits((node.tokenValue * quantity).toString(), tokens.token.decimals);
    const liqTotal = ethers.utils.parseUnits((node.liqValue * quantity).toString(), tokens.liq.decimals);

    if ((tokenTotal.gt(tokens.token.balance) && (node.tokenValue > 0)) || (liqTotal.gt(tokens.liq.balance) && (node.liqValue > 0))) {
        const symbols = [];
        if (tokenTotal.gt(tokens.token.balance) && (node.tokenValue > 0)) symbols.push(tokens.token.symbol);
        if (liqTotal.gt(tokens.liq.balance) && (node.liqValue > 0)) symbols.push(tokens.liq.symbol);

        return (
            <button className="btn btn-gradient-primary text-sp" disabled>Insufficient {symbols.join('/')} balance</button>
        )
    }

    const butonLabel = quantity === 1 ? 'Mint 1 Igloo' : `Mint ${quantity} Igloos`;
    return (
        <button className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp"
                onClick={() => mint.mutate({ nodeId : node.nodeId, quantity : quantity }, { onSuccess: () => { reloadIt() }})}
                disabled={quantity < 1 || mint.isLoading}
        >{mint.isLoading && <Spinner as="span" animation="border" size="sm" /> } { butonLabel }</button>
    );
};

const SingleNode = (props) => {
    const [quantity, setQuantity] = useState(1);
    const increment = useCallback(() => setQuantity(q => q + 1), [])
    const decrement = useCallback(() => setQuantity(q => q === 1 ? 1 : q - 1), [])

    const nid       = props.node.nodeId;
    const config    = nodesList[nid];

    const nodeMintPrice = () => {
        let tokenPrice = props.node.tokenValue * quantity;
        let liqPrice = props.node.liqValue * quantity;

        return (tokenPrice > 0 ? (tokenPrice + ' ' + props.tokens.token.symbol + (liqPrice > 0 ? ' + ' : '')) : '')
            +  (liqPrice > 0 ? liqPrice + ' ' + props.tokens.liq.symbol : '');
    };

    const calculateAPR = () => {
        const yROI = props.node.multiplier * 365;
        const apr = 100 * yROI / (props.node.tokenValue * 2);
        return Math.round(apr * 10) / 10
    }

    return (
        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div className="card text-center node-card rounded h-100">
                <img className="img-fluid node-img mx-auto mt-5" src={ config.image } alt="Node pic" />
                <div className="card-body">
                    <p className="node-name text-sp text-primary mt-2">{ config.name }</p>
                    <p className="node-price text-sp text-primary mt-2 mb-0">{ nodeMintPrice() }</p>
                    <div className="node-roi text-sp text-primary mt-2">
                        <p>{ props.node.multiplier * quantity } {props.tokens.token.symbol}/DAY</p>
                    </div>
                    <div className="node-roi text-sp text-primary mb-2">APR { calculateAPR() }%</div>
                    <InputGroup className="mb-3">
                        <button className="btn btn-gradient-primary text-sp mr-50" onClick={ decrement } disabled={quantity === 1}>-</button>
                        <input readOnly className="text-sp form-control" value={quantity} style={{ textAlign: 'center', borderRadius: '0.358rem' }} />
                        <button className="btn btn-gradient-primary text-sp ml-50" onClick={increment}>+</button>
                    </InputGroup>

                    <MintButton quantity={ quantity } { ...props } />
                </div>
            </div>
        </div>
    )
};

const Mint = ({ nodeList, tokens, reloadIt }) => {
    const { isActive }  = useWeb3React();
    if (!isActive) {
        return (
            <div id="mint-nodes">
                <h1 className="text-primary text-sp">Mint an Igloo</h1>
                <div className="row text-center">
                    <div className="col-12">
                        <ConnectButton btnLabel={"Please connect to your wallet"}/>
                    </div>
                </div>
            </div>
        )
    }

    if (nodeList.isLoading) {
        return (
            <div id="mint-nodes">
                <h1 className="text-primary text-sp">Mint an Igloo</h1>
                <div className="row text-center">
                    <div className="col-12">
                        <Spinner animation="grow" />
                    </div>
                </div>
            </div>
        );
    }

    // const mobileImages = [];
    const nodeCollection = [];
    for (const [index, node] of Object.entries(nodeList.data)) {
        const nid = node.nodeId;
        if (node.exists && (nodesList[nid] !== undefined)) {
            nodeCollection.push(
                <SingleNode node={node} tokens={tokens} key={index} reloadIt={reloadIt} />
            );
        }
    }

    return (
        <div id="mint-nodes">
            <h1 className="text-primary text-sp">Mint an Igloo</h1>
            <div className="row row-flex nodes-list">
                { nodeCollection }
            </div>
        </div>
    );
};

export default Mint;

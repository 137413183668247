import React from "react";

import logo from "../assets/images/logo.png";

const Hero = () => {
    return (
        <div id="hero" className="hero-section">
            <div className="row hero-row">
                <div className="col-12 col-lg-6">
                    <div className="card card-nodes rounded mt-1 mx-auto">
                        <div className="card-body card-hero">
                            <img className="img-fluid hero-nodes-img"  alt="Eskimo Finance" src={ logo } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;

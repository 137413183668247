import nodeLight from "../assets/images/menu/NODE-L.svg";
import nodeDark from "../assets/images/menu/NODE-D.svg";

import dashLight from "../assets/images/menu/DASH-L.svg";
import dashDark from "../assets/images/menu/DASH-D.svg";

import howLight from "../assets/images/menu/HOW-L.svg";
import howDark from "../assets/images/menu/HOW-D.svg";

export const menu = [
    {
        name: "Dashboard",
        route: '/',
        icon_light: dashLight,
        icon_dark: dashDark,
    },
    {
        name: "My Igloos",
        route: '/igloos',
        icon_light: nodeLight,
        icon_dark: nodeDark,
    },
    {
        name: "How To Mint ?",
        route: '/how-to-mint',
        icon_light: howLight,
        icon_dark: howDark,
    }
];

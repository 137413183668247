import { ethers } from "ethers"
import { Provider as MulticallProvider, Contract as MulticallContract, setMulticallAddress } from "ethers-multicall"
import { getNetwork } from "../config";
import { getMulticallAddress } from "../hooks/utils";

const { REACT_APP_CHAIN_ID }    = process.env;
const multicallAddress          = getMulticallAddress();
const networkUsed               = getNetwork(REACT_APP_CHAIN_ID);

/** return a cached provider */
let providerCache = null;

export const getProvider = () => {
    if (!providerCache) {
        providerCache = new ethers.providers.JsonRpcProvider(networkUsed.rpcUrls[0])
    }

    return providerCache
};

/** return a cached multicall provider */
setMulticallAddress(networkUsed.chainId, multicallAddress);

let multicallProviderCache = null;

export const getMulticallProvider = () => {
    if (!multicallProviderCache) {
        multicallProviderCache = new MulticallProvider(getProvider(), networkUsed.chainId)
    }

    return multicallProviderCache
};

/** create a cached factory from abi */
export const factory = (abi) => {
    let contracts = {};
    let multicallContracts = {};

    return (address) => ({
        read: () => {
            if (!contracts[address]) {
                contracts[address] = new ethers.Contract(address, abi)
            }

            return contracts[address].connect(getProvider())
        },
        write: (signer) => {
            if (!contracts[address]) {
                contracts[address] = new ethers.Contract(address, abi)
            }

            return contracts[address].connect(signer)
        },
        multi: () => {
            if (!multicallContracts[address]) {
                multicallContracts[address] = new MulticallContract(address, abi)
            }

            return multicallContracts[address]
        }
    })
};

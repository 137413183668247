import React from 'react';
import {MODAL_TYPES, useModal} from "../module/modal";

const ConnectButton = ({ btnLabel }) => {
    const { open } = useModal();
    const OpenWalletModal       = () => {
        open(MODAL_TYPES.WALLET_MODAL);
    };
    return (
        <button className="btn btn-gradient-secondary waves-effect waves-float waves-light text-sp mt-2"
                onClick={ OpenWalletModal }>{ btnLabel ? btnLabel : 'Connect' }</button>
    );
};

export default ConnectButton;

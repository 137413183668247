import node1 from "../assets/images/node-1.png";
import node2 from "../assets/images/node-2.png";
import node3 from "../assets/images/node-3.png";
import node4 from "../assets/images/node-4.png";

export const nodesList = {
    0: {
        nid: 0,
        name: 'Nano Igloo',
        image: node1
    },
    1: {
        nid: 1,
        name: 'Micro Igloo',
        image: node2
    },
    2: {
        nid: 1,
        name: 'Mega Igloo',
        image: node3
    },
    3: {
        nid: 1,
        name: 'Giga Igloo',
        image: node4
    }
};

export const nodesAttackMessages = {
    1: "No vulnerable Igloo was found, please, try again in a while",
    2: "The Igloo you attacked was empty",
    3: "The Igloo you attacked has Antarctica's protection",
    4: "You attacked an Igloo and won the battle",
    5: "Oups !! You attacked an Igloo and lost the battle",
    6: "You attacked an Igloo but nobody won, perfect draw",
};

import React from 'react';
import { ConnectRow, EmptyRow, LoadingRow } from './CommonRows';
import {useWeb3React} from "@web3-react/core";
import { nodesList } from "../../config";
import {ethers} from "ethers";
import {constants} from "../../config/constants";

const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleString();
};

const formatAction = (nid, eventType) => {
    const nodeName = (nodesList[nid] !== undefined && nodesList[nid]['name'] !== undefined) ? nodesList[nid]['name'] : "Igloo";
    return (eventType === true) ? `You attacked with your ${nodeName}` : `Your ${nodeName} was attacked`
};

const HistoryRows = ({ history }) => {
    if (history.data.length === 0) {
        return <EmptyRow message={ 'No attack history was found' } rows={ 4 } />
    }

    if (history.isLoading) {
        return <LoadingRow rows={ 4 } />
    }

    return (
        history.data.map((record, index) => (
            <tr key={index} >
                <td>{ formatDate(record.eventDate) }</td>
                <td><span>{ formatAction(record.id, record.eventType) }</span></td>
                <td><span>{ (record.won === true)
                    ? `You won ${parseFloat(ethers.utils.formatUnits(record.amount.toString())).toFixed(2)} ${constants.BASE_TOKEN_SYMBOL}`
                    : `You lost ${parseFloat(ethers.utils.formatUnits(record.amount.toString())).toFixed(2)} ${constants.BASE_TOKEN_SYMBOL}` }</span>
                </td>
            </tr>
        ))
    )
};

const History = (props) => {
    const { isActive } = useWeb3React();

    return (
        <div className="nodes-table-wrapper table-responsive">
            <table className="nodes-table table mb-0">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Action Type</th>
                        <th scope="col">Result</th>
                    </tr>
                </thead>
                <tbody id="history">
                {!isActive
                    ? <ConnectRow />
                    : <HistoryRows { ... props } />
                }
                </tbody>
            </table>
        </div>
    );
};

export default History;

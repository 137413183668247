import React, {useCallback, useState} from "react"
import {InputGroup, Modal} from "react-bootstrap"
import {MODAL_TYPES, useModal} from "../module/modal"
import {useWeb3React} from "@web3-react/core";
import {nodesList} from "../config";
import { useAttack } from "../hooks/nodes";
import {useTokenInfo} from "../hooks/token";

const useToken = () => {
    const tokenInfo = useTokenInfo();

    return {
        isLoading: tokenInfo.isLoading,
        data: { ...tokenInfo.data }
    }
};

export const AttackModal = () => {
    const { isActive }  = useWeb3React();
    const attack        = useAttack();

    const { isOpen, store, close }  = useModal();
    const { modalType, modalProps } = store || {};
    const { node, nodeInfo, onSuccess } = modalProps || {};

    const [quantity, setQuantity] = useState(1);
    const increment = useCallback(() => setQuantity(q => q + 1), [])
    const decrement = useCallback(() => setQuantity(q => q === 1 ? 1 : q - 1), [])
    const token = useToken();

    if (!isActive || !node) return null;

    const handleClose = () => {
        setQuantity(1);
        close();
    };

    const displayModal = isOpen && modalType === MODAL_TYPES.ATTACK_MODAL;

    return (
        <Modal size="lg" className="sp-modal" centered show={ displayModal } onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="text-sp">Attack other Igloos</Modal.Title>
                <button onClick={handleClose} type="button" className="close"><span aria-hidden="true">×</span><span
                    className="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body className="font-weight-bolder text-center">
                {(nodesList[node.id].image !== undefined) &&
                <div className="row">
                    <div className="col-12">
                        <img style={{maxHeight: '155px'}} className="img-fluid mb-2" src={nodesList[node.id].image} alt={ 'title' } />
                    </div>
                </div>
                }

                <h3 className="text-sp">You are about to attack a random Igloo</h3>
                <div className="row mt-1">
                    <div className="mx-auto col-6">
                        <h5 className="text-sp">Unsecured Igloo :  {node.count - node.securedCount - quantity}</h5>
                        <h5 className="text-sp">Price  : {quantity * nodeInfo.securityTax} { token.data.symbol }</h5>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="mx-auto col-6">
                        <InputGroup className="mb-1">
                            <button className="btn btn-gradient-primary text-sp mr-50" onClick={ decrement } disabled={quantity === 1}>-</button>
                            <input readOnly className="text-sp form-control" value={quantity} style={{ textAlign: 'center', borderRadius: '0.358rem' }} />
                            <button className="btn btn-gradient-primary text-sp ml-50" onClick={increment} disabled={quantity >= (node.count - node.securedCount)}>+</button>
                        </InputGroup>
                    </div>
                </div>

                <button className="btn btn-gradient-primary waves-effect waves-float waves-light text-sp mt-2"
                        onClick={() => attack.mutate({ nodeId : node.id, fct : handleClose }, { onSuccess: () => { if (onSuccess !== undefined) {onSuccess()} }})}>
                    Attack
                </button>
            </Modal.Body>
        </Modal>
    )
};

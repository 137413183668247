export default {
    nodes: {
        84532: '0x64181d0eC0ddEeA15e02e3ECe9a8d80Fb0c534ff'
    },
    token: {
        84532: '0x74098B5CbDa30C6b86E8e0BfDD076408631c6f53'
    },
    liq: {
        84532: '0x6E2c0695F1EC6eAC90C1C4A8bbaF6dD26651d2D1'
    },
    vault: {
        84532: '0xb0D64D90105694Ea89bdF3c3DDAE6BDa3b7AC3eC'
    },
    multicall: {
        84532: '0x9DE9F9563620B4D9DFbc958D66db4cCA9C096891'
    }
};

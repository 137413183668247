import React from "react";

import { SpeedInsights } from "@vercel/speed-insights/react"

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Snowfall from 'react-snowfall'
function App(props) {
    return (
        <div className="vertical-layout vertical-menu-modern navbar-floating footer-static  menu-expanded pace-done">
            <Header/>
            <Sidebar history={props.history}/>
            <div className="app-content content" style={{ position: 'relative' }}>
                <Snowfall />
                <div className="content-overlay"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        <section id="dashboard-analytics">
                            <Hero />
                            {props.children}
                        </section>
                    </div>
                </div>
                <Footer />
                <SpeedInsights />
            </div>


            <button className="btn btn-gradient-primary btn-icon scroll-top" onClick={ () => { window.scroll({top: 0, left: 0, behavior: 'smooth' }); }} type="button">
                <i data-feather="arrow-up"></i>
            </button>
        </div>
    );
}

export default App;

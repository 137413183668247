const DEFAULT_CHAIN_ID = 42161;

export const networkParams = {
    42161: {
        chainId: 42161,
        chainName: 'Arbitrum One',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://arbitrum-mainnet.infura.io'],
        blockExplorerUrls: ['https://arbiscan.io'],
        iconUrls: ["https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg"]
    },
    421614: {
        chainId: 421614,
        chainName: 'Arbitrum Sepolia',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://sepolia-rollup.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://sepolia.arbiscan.io'],
        iconUrls: ["https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg"]
    },
    43113: {
        chainId: 43113,
        chainName: 'Avalanche Fuji Testnet',
        nativeCurrency: {
            name: 'Avax',
            symbol: 'AVAX',
            decimals: 18,
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://testnet.snowtrace.io'],
        iconUrls: ["https://cryptologos.cc/logos/avalanche-avax-logo.png?v=022"]
    },
    43114: {
        chainId: 43114,
        chainName: 'Avalanche Mainnet C-Chain',
        nativeCurrency: {
            name: 'Avax',
            symbol: 'AVAX',
            decimals: 18,
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io'],
        iconUrls: ["https://cryptologos.cc/logos/avalanche-avax-logo.png?v=022"]
    },
    84532: {
        chainId: 84532,
        chainName: 'Base Sepolia',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://rpc.notadegen.com/base/sepolia'],
        blockExplorerUrls: ['https://sepolia.basescan.org/'],
        iconUrls: ["https://images.mirror-media.xyz/publication-images/cgqxxPdUFBDjgKna_dDir.png"]
    },
};

export const getNetwork = (chainId) => {
    return (chainId in networkParams) ? networkParams[chainId] : networkParams[DEFAULT_CHAIN_ID];
};

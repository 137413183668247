import React from 'react';
import ReactDOM from 'react-dom';

import 'react-toastify/dist/ReactToastify.min.css';

import './assets/css/vendors/vendors.min.css'
import './assets/css/vendors/toastr.min.css'
import './assets/css/vendors/ext-component-toastr.css'

import './assets/css/dapp/bootstrap.css'
import './assets/css/dapp/bootstrap-extended.css'
import './assets/css/dapp/colors.css'
import './assets/css/dapp/components.css'
import './assets/css/dapp/vertical-menu.css'
import './assets/css/style.css'

import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';
import Routes from "./routes";

ReactDOM.render(
    <Routes />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

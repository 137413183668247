import React from "react";

const Footer = () => {
    return (
        <footer className="footer-br text-center mt-5">
            <p className="clearfix">
                <span className="text-center">
                    <a href="https://twitter.com/EskimoFi_Base" target="_blank" rel="noreferrer"
                       className="text-sp text-primary no-decor">Twitter</a> <span className="text-secondary">| </span>
                    <a href="https://eskimo.gitbook.io/eskimo-finance" target="_blank" rel="noreferrer"
                       className="text-sp text-primary no-decor">Docs</a> <span className="text-secondary">| </span>
                    <a href="https://eskimo.gitbook.io/eskimo-finance/eskimo-finance-igloos/contracts" target="_blank" rel="noreferrer"
                       className="text-sp text-primary no-decor">Contracts</a>
                </span>
            </p>
            <p className="clearfix pb-1">
				<span className="text-sp text-primary text-center">COPYRIGHT &copy; {new Date().getFullYear()}
                    <a className="ml-25 text-primary" href="https://www.eskimo.finance" rel="noopener noreferrer" target="_blank">Eskimo Finance.</a>
                    <span className="d-none d-sm-inline-block">&nbsp;All rights Reserved</span>
				</span>
            </p>
        </footer>
    );
};

export default Footer;

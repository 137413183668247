import React from "react";
import {Spinner} from "react-bootstrap";
import ConnectButton from "../../components/ConnectButton";

export const ConnectRow = () => {
    return (
        <tr>
            <td className="text-center" colSpan="7">
                <ConnectButton btnLabel={ "Please connect to your wallet" } />
            </td>
        </tr>
    )
};


export const EmptyRow = ({ message, rows }) => {
    return (
        <tr>
            <td className="text-center" colSpan={ rows }>{ message }</td>
        </tr>
    )
};

export const LoadingRow = ({ rows, type, size }) => {
    return (
        <tr>
            <td className="text-center" colSpan={ rows }>
                <Spinner as="span" animation={ type ? type : "grow" } size={ size ? size : "sm" } />
            </td>
        </tr>
    )
};

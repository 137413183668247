import React, { useState } from 'react';
import {useQueryClient} from "react-query";

import Mint from "./Nodes/Mint";
import Inventory from "./Nodes/Inventory";
import History from "./Nodes/History";
import { useNodeList, useUserNodes, useUserPendingRewardsInfo, useUserHistory, useWithdrawAll } from "../hooks/nodes";
import { useTokenInfo, useLiqInfo } from "../hooks/token";
import {constants} from "../config/constants";

const useTokens = () => {
    const tokenInfo = useTokenInfo();
    const liqInfo = useLiqInfo();

    return {
        isLoading: tokenInfo.isLoading || liqInfo.isLoading,
        token: { ...tokenInfo.data },
        liq: { ...liqInfo.data },
    }
};

const Nodes = () => {
    const nodeList      = useNodeList();
    const tokens        = useTokens();
    const userNodes     = useUserNodes();
    const rewardsInfo   = useUserPendingRewardsInfo();
    const withdrawAll   = useWithdrawAll();
    const history       = useUserHistory();

    const attackEnabled = constants.ATTACK_ENABLED;

    const queryClient = useQueryClient();
    const [count, setCount] = useState(0);

    const reloadIt = () => {
        queryClient.invalidateQueries('userNodes');
        queryClient.invalidateQueries('nodeCanAttack');
        queryClient.invalidateQueries('rewardsInfo');
        setCount(count + 1);
    };

    return (
        <div className="mx-2">
            <Mint nodeList={ nodeList } tokens={ tokens } reloadIt={reloadIt} />

            <div id="nodes-info">
                <h1 className="text-primary text-sp my-3">My Inventory</h1>

                <div className="bd-example bd-example-tabs">
                    <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active text-sp" id="nodes-list-tab" data-toggle="pill" href="#nodes-list"
                               role="tab" aria-controls="nodes-list" aria-selected="true">My Igloos</a>
                        </li>
                        { attackEnabled &&
                            <li className="nav-item" role="presentation">
                                <a className="nav-link text-sp" id="attack-history-tab" data-toggle="pill" href="#attack-history"
                                   role="tab" aria-controls="attack-history" aria-selected="false">Attack History</a>
                            </li>
                        }
                        { !rewardsInfo.isLoading && rewardsInfo.data.userTotalPendingRewards > 0 &&
                            <li className="ml-auto nav-collect-all">
                                <button className="btn btn-gradient-secondary text-sp mt-1 mt-md-0" onClick={ () => withdrawAll.mutate(void(0) , { onSuccess: () => { reloadIt() }}) }>Collect All</button>
                            </li>
                        }

                    </ul>
                    <div className="tab-content mt-1 mt-sm-0" id="pills-tabContent">
                        <div className="tab-pane fade active show" id="nodes-list" role="tabpanel" aria-labelledby="nodes-list-tab">
                            <Inventory nodeList={ nodeList } tokens={ tokens } userNodes={ userNodes } rewardsInfo={ rewardsInfo } reloadIt={ reloadIt } />
                        </div>
                        { attackEnabled &&
                            <div className="tab-pane fade" id="attack-history" role="tabpanel" aria-labelledby="attack-history-tab">
                                <History history={ history } />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Nodes;

import React from 'react';

import { MoreHorizontal } from "react-feather";
import { useWeb3React } from "@web3-react/core";
import { nodesList } from "../../config";
import { MODAL_TYPES, useModal } from "../../module/modal";
import { useWithdraw } from "../../hooks/nodes";
import { ConnectRow, EmptyRow, LoadingRow } from './CommonRows';
import {constants} from "../../config/constants";

const attackEnabled = constants.ATTACK_ENABLED;

const InventoryRows = ({ nodeList, tokens, userNodes, rewardsInfo, reloadIt }) => {
    const collect = useWithdraw();

    return (
        <>
            { (userNodes.isLoading || tokens.isLoading || nodeList.isLoading || rewardsInfo.isLoading)
                ? <LoadingRow rows={ 7 } />
                : (userNodes.data.length === 0)
                    ? <EmptyRow message={ 'You have no Igloo' } rows={ 7 }/>
                    : userNodes.data.map((node, index) => <SingleRow collect={collect} key={index} node={ node }
                                                                     nodeList={ nodeList } tokens={ tokens }
                                                                     rewardsInfo={ rewardsInfo } reloadIt={ reloadIt } />)
            }

            {(rewardsInfo.isLoading || tokens.isLoading)
                ? null
                : <tr className="table-light">
                    <th><span className="text-secondary">TOTAL</span></th>
                    <th className="d-none d-sm-table-cell"><span className="text-secondary">{ rewardsInfo.data.userTotalNodes }</span></th>
                    { attackEnabled && <th className="d-none d-sm-table-cell"></th> }
                    <th className="d-none d-sm-table-cell"></th>
                    <th className="d-none d-sm-table-cell"></th>
                    <th><span className="text-secondary">{ rewardsInfo.data.userTotalPendingRewards } { tokens.token.symbol }</span></th>
                    <th></th>
                </tr>
            }
        </>
    );
};

const SingleRow = ({ nodeList, tokens, node, rewardsInfo, collect, reloadIt }) => {

    const { open } = useModal();

    const OpenSecureModal = () => {
        open(MODAL_TYPES.SECURE_MODAL, {
            node: node,
            nodeInfo: nodeList.data[node.id],
            onSuccess: reloadIt
        });
    };

    const OpenAttackModal = () => {
        open(MODAL_TYPES.ATTACK_MODAL, {
            node: node,
            nodeInfo: nodeList.data[node.id],
            onSuccess: reloadIt
        });
    };

    if (nodesList[node.id] === undefined) {
        return null;
    }

    let lastClaim       = Math.floor((Date.now()/1000 - node.lastClaim) / 3600 / 24);
    lastClaim           = (lastClaim <= 0) ? 'Less than a day' : `${lastClaim} days ago`;
    const secured       = (node.securedCount === 0) ? 'None' : node.securedCount + ' of ' + node.count;
    const pendingReward = rewardsInfo.data.rewards[node.id];

    return (
        <tr>
            <td>{ nodesList[node.id].name }</td>
            <td className="d-none d-sm-table-cell"><span>{ node.count }</span></td>
            { attackEnabled && <td className="d-none d-sm-table-cell"><span>{ secured }</span></td> }

            <td className="d-none d-sm-table-cell"><span>{ nodeList.data[node.id].multiplier } { tokens.token.symbol } /Igloo /day </span></td>
            <td className="d-none d-sm-table-cell"><span>{ lastClaim }</span></td>
            <td><span>{ pendingReward === undefined ? 0 : pendingReward } { tokens.token.symbol }</span></td>

            {/*Desktop Actions */}
            <td className="d-none d-sm-table-cell">
                <span>
                    { (pendingReward > 0) && <button onClick={() => collect.mutate({ nodeId : node.id }, { onSuccess: () => { reloadIt() }})}
                                                     className="btn btn-gradient-secondary text-sp mb-50 mx-50">Collect</button>
                    }
                    { attackEnabled && (node.securedCount < node.count) && <button className="btn btn-gradient-secondary text-sp mb-50 mx-50" onClick={ OpenSecureModal }>Secure</button>
                    }

                    { attackEnabled && node.canAttack && <button className="btn btn-gradient-secondary text-sp mb-50 mx-50" onClick={ OpenAttackModal }>Attack</button> }
                </span>
            </td>

            {/*Mobile Actions */}
            <td className="d-table-cell d-sm-none">
                <div className="btn-group dropleft">
                    <button type="button" className="btn btn-gradient-primary dropdown-toggle"
                            data-toggle="dropdown" aria-expanded="false"><MoreHorizontal />
                    </button>
                    <div className="dropdown-menu text-center">
                        <div className="p-50">
                            <button className="btn btn-gradient-secondary text-sp mb-75">Details</button>
                            { (pendingReward > 0) && <button onClick={() => collect.mutate({ nodeId : node.id })}
                                                             className="btn btn-gradient-secondary text-sp ">Collect</button>
                            }
                        </div>
                        <div className="dropdown-divider"></div>
                        { attackEnabled &&
                            <div className="p-50">
                                { (node.securedCount < node.count) && <button className="btn btn-gradient-secondary text-sp mb-75" onClick={ OpenSecureModal }>Secure</button> }
                                { node.canAttack && <button className="btn btn-gradient-secondary text-sp" onClick={ OpenAttackModal }>Attack</button> }
                            </div>
                        }
                    </div>
                </div>
            </td>
        </tr>
    )
};

const Inventory = (props) => {
    const { isActive } = useWeb3React();

    return (
        <div className="nodes-table-wrapper table-responsive">
            <table className="nodes-table table mb-0">
                <thead>
                <tr>
                    <th scope="col">Igloo type</th>
                    <th className="d-none d-sm-table-cell" scope="col">Number of Igloos</th>
                    { attackEnabled &&
                        <th className="d-none d-sm-table-cell" scope="col">Secured ?</th>
                    }

                    <th className="d-none d-sm-table-cell" scope="col">Daily rewards</th>
                    <th className="d-none d-sm-table-cell"  scope="col">Last claim</th>
                    <th scope="col">Pending rewards</th>
                    <th className="d-none d-sm-table-cell" scope="col">Actions</th>
                    <th className="d-table-cell d-sm-none" scope="col"></th>
                </tr>
                </thead>
                <tbody id="nodes">
                {!isActive
                    ? <ConnectRow />
                    : <InventoryRows { ... props } />
                }
                </tbody>
            </table>
        </div>
    );
};

export default Inventory;
